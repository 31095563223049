export default {
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    textTransform: 'uppercase',
  },
  text: {
    lineHeight: 'body',
    fontFamily: 'body',
    fontWeight: 'body',
    fontSize: '1rem',
    color: 'text',
  },
  navLink: { fontFamily: 'heading' },
  link: {
    textDecoration: 'none',
    color: 'unset',
  },
};
