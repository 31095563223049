export default {
  text: '#fdeec2',
  primary: '#cf0047',
  secondary: '#96d6db',
  background: '#203231',
  backgroundSecondary: '#301008',
  light: '#FFF',
  dark: '#020303',
  green: '#203132',
};
