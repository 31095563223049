const variantName = 'menuV1';

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      justifyContent: 'center',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: ['2rem 1rem', '2rem', '3rem', '3rem 5rem'],
      backgroundSize: 'auto',
      backgroundImage:
        'url(https://www.transparenttextures.com/patterns/lined-paper-2.png)',
      backgroundRepeat: 'repeat',
      backgroundColor: '#efece5',
      color: 'dark',

      '.add-ons': {
        border: '3px dashed black',
      },
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      margin: '1rem 1rem 2rem',
      cursor: 'pointer',
      justifyContent: 'center',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '50%', // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      marginBottom: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px',
      },
    },

    sectionTab: {
      margin: '0.25rem',
      minWidth: 'max-content',
      maxWidth: 'max-content',
      width: '100%',
      border: '1px solid black',
      padding: '0.75rem 1rem 1rem',
      cursor: 'pointer',
      color: 'text',
      backgroundColor: 'secondary',
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: '1.5rem',
      '&:hover': {
        backgroundColor: 'black',
        color: '#fff',
        transition: 'all 0.5s',
      },
    },

    sectionTabActive: {
      variant: `${variantName}.sectionTab`,
      backgroundColor: 'primary',
      color: 'dark',
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainerWrapper: {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      // maxWidth: '1000px',
      // margin: ' 0 auto',

      '> .allInContainer': {
        position: 'relative',
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1645462880/sites/taboonette/carrot.png)',
        backgroundSize: '80px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 15%',
        '>.menuContainer': {
          display: 'none',
        },
        '> div:nth-of-type(even)': {
          position: 'relative',
          backgroundImage:
            'url(https://res.cloudinary.com/gonation/image/upload/v1645462882/sites/taboonette/tomato.png)',
          backgroundSize: '80px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 15%',
          zIndex: '1',
          '::before': {
            content: "''",
            backgroundImage:
              'url(https://res.cloudinary.com/gonation/image/upload/v1645462880/sites/taboonette/donor.png)',
            backgroundSize: '120px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 35%',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0rem',
            left: '0rem',
            zIndex: '-1',
          },
          '::after': {
            content: "''",
            backgroundImage:
              'url(https://res.cloudinary.com/gonation/image/upload/v1645462881/sites/taboonette/pig.png)',
            backgroundSize: '100px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 80%',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0rem',
            left: '0rem',
            zIndex: '-1',
          },
        },
        '> div:nth-of-type(odd)': {
          position: 'relative',
          backgroundImage:
            'url(https://res.cloudinary.com/gonation/image/upload/v1645462882/sites/taboonette/steak.png)',
          backgroundSize: '80px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left 5%',
          zIndex: '1',

          '::before': {
            content: "''",
            backgroundImage:
              'url(https://res.cloudinary.com/gonation/image/upload/v1645462881/sites/taboonette/kebabs.png)',
            backgroundSize: '120px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 0%',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0rem',
            left: '0rem',
            zIndex: '-1',
          },
          '::after': {
            content: "''",
            backgroundImage:
              'url(https://res.cloudinary.com/gonation/image/upload/v1645462880/sites/taboonette/fish.png)',
            backgroundSize: '100px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 60%',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0rem',
            left: '0rem',
            zIndex: '-1',
          },
        },
      },
    },

    allInContainer: {
      padding: ['1rem 2rem', '2rem 3rem', '3rem', '4rem'],
    },

    menuContainer: { paddingBottom: '2rem' },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))'],
      backgroundColor: 'secondary',
      margin: ['1rem 0', '0.5rem'],
      overflow: 'hidden',
      ':hover .cellImageContainer ': {
        transform: 'scale(1.2)',
      },
    },

    menuCell: {
      position: 'relative',
      flexWrap: 'wrap',
      height: '100%',
      backgroundColor: 'background',
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
      transition: 'all ease-in-out 0.6s',
      filter: 'brightness(0.9)',
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cellName: {
      fontSize: ['1.75rem', '3rem', '4rem', '4.5rem'],
      fontWeight: 'normal',
      textTransform: 'uppercase',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, -50%)',
      fontFamily: 'display',
      textAlign: 'center',
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      fontSize: '2rem',
      textAlign: 'center',
      margin: '2rem',
      color: 'primary',
      fontWeight: '300',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '0.5rem',
      textAlign: 'center',
      textTransform: 'capitalize',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1rem 1.5rem'],
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
      '::before': {
        content: "''",
        width: '80px',
        height: '6px',
        backgroundColor: 'primary',
        margin: '1.5rem',
      },
      '::after': {
        content: "''",
        width: '80px',
        height: '6px',
        backgroundColor: 'primary',
        margin: '1.5rem',
      },
    },
    menuSectionDescription: {
      textAlign: 'center',
      fontSize: 4,
      marginBottom: '2rem',
      padding: '1rem',
      opacity: '0.8',
      maxWidth: '700px',
      margin: '0rem auto 2rem',
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'stretch',
      marginBottom: 0,
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem', '', '0 1rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))'],
      margin: '0rem 0rem 1rem',
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`,
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px'],
      // padding: '0 0.75rem 0 0',
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: ['130px', '150px', '175px'],
      cursor: 'pointer',
      borderRadius: '10px',
      padding: '0rem 0.75rem 0rem 0rem',
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none',
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      // margin: '1rem 0rem',
      position: 'relative',
    },
    menuItemName: {
      fontSize: ['1.2rem', '1.3rem', '1.4rem', '1.5rem'],
      fontWeight: 'bold',
      // marginBottom: '1rem',
      paddingRight: '8px',
      color: 'dark',
      // textTransform: 'uppercase',
      // fontWeight: 'bold',
      margin: '0rem 0rem 0.25rem',
      fontFamily: 'heading',
      textAlign: 'center',
    },
    menuItemDescription: {
      fontSize: ['1.2rem', '', '1.4rem'],
      whiteSpace: 'break-spaces',
      width: '100%',
      fontWeight: '500',
      opacity: '0.8',
      textAlign: 'center',
      color: 'primary',
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end',
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      color: 'primary',
      opacity: '0.8',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: ['1rem', '1rem', '1rem'],
    },

    itemVariantsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0.5rem 0rem 0.5rem -0.75rem',
      order: '4',
      'div:last-of-type': {
        border: 'none',
      },
    },

    variantsContainer: {
      display: 'flex',
      padding: '0.75rem',
      borderRight: '2px #202020 solid',
    },

    labelTitle: {
      display: 'flex',
      fontWeight: 'bold',
    },
    variantContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem',
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px',
      opacity: '0.8',
      fontWeight: '600',
    },
    menuItemPriceVariants: {
      color: 'primary',
      opacity: '0.8',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: ['1.1rem', '1.2rem', '1.3rem'],
      fontFamily: 'body',
    },

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
      marginBottom: '1rem',
      width: '100%',
      display: 'none!important',
    },

    menuDropdownBtn: {
      variant: 'buttons.primary',
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      opacity: '0.8',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center',
      },
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline',
        },
      },
    },
  },
};
