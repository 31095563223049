export default {
  heading: 'Museo Slab, serif',
  body: 'Montserrat, serif',
  monospace: 'Menlo, monospace',
  display: 'Italiana, sans-serif',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Italiana',
    'Cinzel',
  ],
  customFamilies: ['Museo Slab'],
  customUrls: ['https://www.gonation.biz/fonts/museo-slab/museo-slab.css'],
};
